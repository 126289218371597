// reset code start here ********************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: "Noto Sans", sans-serif;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: normal;
    -webkit-overflow-scrolling: touch;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    cursor: pointer;
    text-decoration: none;
}

img {
    max-width: 100%;
}

button {
    cursor: pointer;
    outline: none;
    border: none;
}

textarea {
    outline: none;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

// reset code end here ********************************************************/

.preload {
    height: 100vh;
}

// body code start here ************************************/
body {
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-family: "Noto Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    background: #141413;
}

// body code end here ************************************/



// html code start here ************************************/
html {
    // set base font size (all font-size declarations are rem so they point to this)
    font-size: 16px;
    -webkit-text-size-adjust: auto;
    -ms-text-size-adjust: 16px;

    @media screen and (min-width: 320px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1024px) {
        font-size: 12px;
    }

    @media screen and (min-width: 1600px) {
        font-size: 16px;
    }
}

// html code end here ************************************/



// variable code start here *******************************/
:root {
    // color variable code start here *******************************************/
    --primary: #FC4355;
    --white: #ffffff;
    --text_dark: #000000;
    --text_light: #9d9d9d;
    --bg_dark: #262626;
    // color variable code start here *******************************************/

    // font size variable code start here ***************************************/
    --f98: 6.125rem;
    --f54: 3.375rem;
    --f44: 2.75rem;
    --f28: 1.75rem;
    --f24: 1.5rem;
    --f20: 1.25rem;
    --f18: 1.125rem;

    --bold: 600;
    --normal: 400;

    h1 {
        font-size: var(--f98);
        line-height: 6.125rem;
        color: var(--white);
        font-weight: var(--bold);

        @media screen and (max-width: 720px) {
            font-size: 4.25rem;
            line-height: 5rem;
        }
        &.title{
            font-size: var(--f54);
            line-height: 4rem;
            color: var(--white);
            font-weight: var(--bold);
            @media screen and (max-width: 720px) {
                font-size: var(--f54);
                line-height: 4rem;
            }
        }
    }

    h2 {
        font-size: var(--f54);
        line-height: 4rem;
        color: var(--white);
        font-weight: var(--bold);
    }

    h3 {
        font-size: var(--f44);
        line-height: 3.375rem;
        color: var(--white);
        font-weight: var(--bold);
    }

    p {
        font-size: var(--f24);
        line-height: 2.125rem;
        color: var(--white);
    }

    // font size variable code end here ***************************************/
}

// Variable code end here *********************************/



// mixin code start here *******************************/
.flex_box {
    display: flex;
    align-items: center;
    justify-content: center;

    &.align-start {
        align-items: start;
    }

    &.align-end {
        align-items: end;
    }
}

.flex_box_column {
    display: flex;
    flex-direction: column;
    align-items: start;
}

// mixin code code here ***********************************/



// header code start here *******************************/
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.125rem;
    background: #1D1D1D;
    padding: 1.25rem 3.75rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    border: 1px solid #303030;
    border-width: 0 0 1px;

    @media screen and (max-width: 1024px) {
        gap: 1.875rem;
        padding: 1.25rem;
    }

    .header_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.125rem;

        @media screen and (max-width: 1024px) {
            gap: 1.25rem;
        }

        &.desktop {
            @media screen and (max-width: 720px) {
                display: none;
            }
        }

        li {
            text-align: center;
            margin:0.625rem 0;
            .nav-inside{
                display:none; // Initially hide
                &.show {
                    display: block; // Show when active
                }
    
            }
            a {
                font-size: var(--f18);
                color: var(--text_light);
                position: relative;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.625rem;
                i{
                    display: flex;
                }
                
                &:hover {
                    color: var(--white);
                    svg path{
                        stroke: var(--white);
                    }
                }

                &.active {
                    color: var(--white);
                    svg path{
                        stroke: var(--white);
                    }

                    &::after {
                        content: "";
                        height: 0.0625rem;
                        background: var(--primary);
                        bottom: -0.1875rem;
                        left: 0;
                        right: 0;
                        position: absolute;
                    }
                }
                svg path{
                    stroke: var(--text_light);
                }
            }
        }
    }

    // header_nav code end here ***************************/
    .menu_icon {
        display: none;
        position: absolute;
        right: 1.25rem;

        &.mobile {
            @media screen and (max-width: 720px) {
                display: block;
            }
        }

    }

    .desktop_navigation {
        display: flex;
    }

    .mobile_navigation {
        position: absolute;
        top: 5.3rem;
        background: black;
        left: 0;
        right: 0;
        padding: 1.25rem;
        display: none;
        align-items: center;
        flex-direction: column;
        gap: 1.25rem;

        @media screen and (max-width: 720px) {
            display: flex;
        }

        .header_nav {
            flex-direction: column;
        }
    }
}

footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 3.125rem;
    background: #1D1D1D;
    // padding: 3.125rem 3.75rem;
    padding: 2.425rem 3.75rem;
    border: 1px solid #303030;
    border-width: 1px 0 0;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 820px) {
        gap: 1.875rem;
        padding: 1.25rem;
        flex-direction: column;
        align-items: center;
    }

    // header_nav code start here ***************************/
    .header_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.125rem;
        flex-wrap: wrap;

        @media screen and (max-width: 1024px) {
            gap: 1.25rem;
        }

        li {
            a {
                font-size: var(--f18);
                color: var(--text_light);
                position: relative;
                white-space: nowrap;

                &:hover {
                    color: var(--white);
                }

                &.active {
                    color: var(--white);
                }
            }
        }
    }

    // header_nav code end here ***************************/
    .social_block {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        @media screen and (max-width: 820px) {
            align-items: center;
        }

        >div {
            display: flex;
            align-items: center;
            gap: 0.9375rem;
        }
    }

    .footer_nav_block {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        >div {
            display: flex;
            gap: 2.5rem;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 400px) {
                display: block;
                text-align: center;
            }
            p {
                font-size: var(--f18);
                font-weight: 500;
                @media screen and (max-width: 400px) {
                    margin-bottom: 10px;
                }

            }

            a {
                font-size: var(--f18);
                color: var(--text_light);
                position: relative;

                &:hover {
                    color: var(--white);
                }
            }
        }
    }
}

// header code end here *********************************/



// language_box code start here *********************************/
// .language_box{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 0.3125rem;
//     span,code{
//         font-size: var(--18);
//         color: var(--text_light);
//         cursor: pointer;
//         &.active,
//         &:hover{
//             color: var(--white);
//         }
//     }
// }
// language_box code end here *********************************/



// content_section code start here*******************************/
.content_section {
    display: flex;
    flex-direction: column;
    gap: 6.25rem;
    padding: 10.75rem 0 6.25rem;

    @media screen and (max-width: 820px) {
        padding: 8rem 1.25rem 2rem;
        gap: 4.25rem;
    }

    background: url("../images/bg_smile_icons.png") top repeat-x;
}

// content_section code end here*******************************/



// banner_section code start here ***************************/
.banner_section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3.75rem;

    @media screen and (max-width: 820px) {
        padding: 0;
        display: block;
    }

    >div {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        width: 100%;
        position: relative;

        @media screen and (max-width: 820px) {
            display: block;
        }

        .banner_image_content {
            position: absolute;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1.25rem;
            top: 50%;
            transform: translate(0, -50%);

            @media screen and (max-width: 820px) {
                position: initial;
                transform: initial;
                width: 100%;
                //text-align: center;
                display: block;
                margin: 0 auto;
            }

            p {
                margin-top: 2.1875rem;
            }

            img {
                width: 35%;

                &.desktop {
                    display: block;
                    width: 50%;
                    margin-left: -10rem;
                }

                &.mobile {
                    display: none;
                }

                @media screen and (max-width: 820px) {
                    width: 100%;

                    &.desktop {
                        display: none;
                    }

                    &.mobile {
                        display: block;
                    }
                }
            }

            .contact_box {
                display: flex;
                flex-wrap: wrap;
                gap: 2%;

                >div {
                    font-size: var(--f20);
                    width: 49%;

                    @media screen and (max-width: 820px) {
                        width: 100%;
                    }

                    h5 {
                        color: var(--text_light);
                        margin: 1.5625rem 0 0.9375rem;
                    }

                    span {
                        display: block;
                        color: var(--white);
                        line-height: 2.125rem;
                    }
                }
            }
        }

        @media screen and (max-width: 820px) {
            picture img {
                display: none;
            }
        }
    }

    &.left {
        >div {
            justify-content: flex-end;

            .banner_image_content {
                left: 0;

                img {
                    @media screen and (max-width: 820px) {
                        margin-top: 1.25rem;
                    }

                    @media screen and (min-device-width: 480px) and (max-device-width: 820px) {
                        width: 60%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }

    &.right {
        >div {
            justify-content: flex-start;

            .banner_image_content {
                right: 0;

                img {
                    @media screen and (min-device-width: 480px) and (max-device-width: 820px) {
                        width: 60%;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

        }
    }
}

// banner_section code end here ***************************/



// content_banner_section code start here ***************************/
.content_banner_section {
    text-align: center;
    width: 53.75rem;
    margin: 0px auto;
    padding: 4rem 0;
    position: relative;

    &.launching_section {
        min-height: calc(100vh - 30.375rem);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p {
        margin: 1.625rem 0 2.75rem;
        position: relative;

        @media screen and (max-width: 820px) {
            padding: 0 2.875rem;
        }
    }

    @media screen and (max-width: 820px) {
        width: 100%;
    }

    h1 {
        position: relative;

        @media screen and (max-width: 820px) {
            padding: 0 2.875rem;
        }
    }

    i {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 820px) {
            z-index: -1;
        }

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .smile_icon,
    .smileemoj_icon {
        position: absolute;
    }

    .smile_icon {
        left: -10rem;

        // animation: iconanimate 30s infinite;
        @media screen and (max-width: 820px) {
            left: 0;
            top: 0.625rem;
        }
    }

    .smileemoj_icon {
        right: -10rem;

        // animation: iconanimate 15s infinite;
        @media screen and (max-width: 820px) {
            right: 0rem;
            top: 0.625rem;
        }
    }

    .heart_icon {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        position: absolute;
        left: -4rem;
        top: 1.125rem;

        // animation: iconanimate 50s infinite;
        @media screen and (max-width: 820px) {
            left: 0rem;
            width: 3rem;
            height: 3rem;
            top: 3.125rem;
        }
    }

    .theme_icon {
        width: 3.375rem;
        height: 3.375rem;
        line-height: 3.375rem;
        position: absolute;
        right: -3.375rem;
        bottom: 1.125rem;

        // animation: iconanimate 70s infinite;
        @media screen and (max-width: 820px) {
            right: 0rem;
        }
    }

    .call_icon {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        position: absolute;
        left: -10rem;
        top: 1.125rem;

        // animation: iconanimate 10s infinite;
        @media screen and (max-width: 820px) {
            left: 0rem;
        }
    }

    .video_icon {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        position: absolute;
        right: -10rem;
        bottom: -1.125rem;

        // animation: iconanimate 40s infinite;
        @media screen and (max-width: 820px) {
            right: 0rem;
        }
    }

    // @keyframes iconanimate {
    //     0% {opacity: 0;}
    //     50% {opacity: 1;}
    //     100%{opacity: 0px;}
    // }
}

button {
    padding: 1.5rem 5rem;
    text-align: center;
    font-size: var(--f28);
    color: var(--white);
    font-weight: var(--bold);
    background: linear-gradient(180deg, #FC4355 0%, #C31C2C 100%);
    border-radius: 3.125rem;

    a {
        color: var(--white);
    }

    &:hover {
        background: linear-gradient(180deg, #FC4355 0%, #7e1721 100%);
        // box-shadow: 0px 3px 20px 5px #96333D;
    }
}

// content_banner_section code end here ***************************/



// card_section code start here ***********************************/
.card_section {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 2.5rem;
    padding: 0 3.75rem;

    @media screen and (max-width: 820px) {
        flex-direction: column;
        gap: 6.25rem;
        padding: 0;
        text-align: center;
    }

    .card_block {
        flex: 1;
        border-radius: 3.125rem;
        padding: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(1) {
            background: linear-gradient(180deg, #FFF 0%, #FFE9E9 100%);
        }

        &:nth-child(2) {
            background: linear-gradient(180deg, #FFF 0%, #FFF5E9 100%);
        }

        &:nth-child(3) {
            background: linear-gradient(180deg, #FFF 0%, #E9FFEB 100%);
        }

        @media screen and (max-width: 820px) {
            max-width: 37.5rem;
            margin: 0 auto;
        }

        img {
            margin-top: -6.25rem;
        }

        h3 {
            @media screen and (min-width: 1024px) and (max-width: 1400px) {
                font-size: 2.25rem;
                line-height: 2.625rem;
            }
        }

        h3,
        p {
            color: var(--text_dark);
            margin-top: 1.875rem;
        }

        .card2,
        .card3 {
            margin-left: 2.5rem;
        }
    }
}

// card_section code end here ***************************/


// content_block code start here ***************************/
.content_block {
    padding: 0 3.75rem;

    @media screen and (max-width: 820px) {
        padding: 0;
        //text-align: center;
    }

    h2 {
        font-size: var(--f54);
        margin-bottom: 3.75rem;
    }

    h3 {
        font-size: var(--f28);
        margin-top: 2.5rem;
    }

    h4 {
        margin: 3.125rem 0 1.25rem;
        font-size: var(--f28);
        color: var(--white);
        font-weight: var(--bold);
        color: var(--text_light);
    }

    p {
        margin-bottom: 1.875rem;
        font-size: var(--f24);

        a {
            color: var(--primary);
        }
    }
    .desc{
        margin: 1.875rem 0;
    font-size: var(--f24);
    }
    .w-65{
        width: 65%;
        @media screen and (max-width: 820px) {
          width:unset;
        }
    }
    span {
        font-size: var(--f24);
        color: var(--white);
        margin-bottom: 1.25rem;
        display: block;

        b {
            color: var(--text_light);

            @media screen and (max-width: 820px) {
                display: block;
            }
        }
    }
}

// content_block code end here ***************************/



//   animate code start here **********************************/
.animate {

    h3,
    h2 {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity .3s, transform .75s;
    }

    img {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity .3s, transform .75s;
    }

    p {
        opacity: 0;
        transform: translateY(50px);
        transition: opacity .3s, transform 1.25s;
    }

    +picture {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity .3s, transform .75s;
        z-index: -1;
    }

    &.animateIt {

        h3,
        h2 {
            opacity: 1;
            transform: translateY(0);
        }

        p {
            opacity: 1;
            transform: translateY(0);
        }

        img {
            opacity: 1;
            transform: translateY(0);
        }

        +picture {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.animate_hover {
    h3 {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity .3s, transform .25s;
    }

    p {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity .3s, transform .25s;
    }

    img {
        opacity: 1;
        transform: translateY(0px);
        transition: opacity .3s, transform .25s;
    }

    &:hover {
        h3 {
            opacity: 1;
            transform: translateY(-5px);
        }

        p {
            opacity: 1;
            transform: translateY(-5px);
        }

        img {
            opacity: 1;
            transform: translateY(5px);
        }
    }
}

//   animate code end here **********************************/

.delete_points,.help_points {
    list-style: circle;
    margin-top: 2.25rem;
    p {
        position: relative;
        font-size: var(--f24);
        color: var(--white);
        display: block;
        padding-left: 1.25rem;
        

        &::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--text_light);
            left: 0;
            top: 0.9688rem;
            position: absolute;
        }
    }
}

// help_content_block code here ***********************************/
.help_page{
    padding: 0;
    @media screen and (max-width: 820px) {
        padding: 8rem 1.25rem 2rem;
}
span{
    font-size: var(--f24);
    color: var(--text_light);
    margin-bottom: 1.25rem;
    display: block;
}
}

.sidebar_nav_desktop {
    max-width: 28rem;
    margin-top: 4.813rem;
    background: #1D1D1D;
    border: 1px solid #303030;
    display: block;
    z-index: 1;
    // height: calc(100vh - 237px);
    height: 100vh;
    overflow-y: auto;
    position: fixed;
    width: 100%;

    @media screen and (max-width: 720px) {
        display: none;
    }

    ul {
        margin:0 1rem;
        li {
            margin: 0.8375rem 0;
            
            .nav-inside{
                display:none; // Initially hide
                &.show {
                    display: block; // Show when active
                }
                li{
                    a{
                        padding-left:3.125rem;
                    &.active{
                        background-color:#373435;
                        color:var(--white);
                    }
                    }
                }
                
                
            }
            

            a {
                font-size: var(--f18);
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content:space-between;
                border-radius: 6px;
                padding: 0.8125rem 1.4125rem;
                text-decoration: none;
                color: var(--text_light);
                gap: 65px;
                i{
                    display: flex;
                }

                &:hover {
                    
                    color: var(--white);
                    svg path{
                        stroke: var(--white);
                    }
                }

                &.active {
                    background-color: #373435; 
                    color: var(--white);
                    svg path{
                        stroke: var(--white);
                    }
                }
                svg path{
                    stroke: var(--text_light);
                }
            }

        }
    }
}

.help_content_block {
    display: flex;
    flex-direction: row;
    .faq_content {
        padding: 10.75rem 0 6.25rem;
        margin-left: 27rem;
        overflow-y: auto;
        width: calc(100% - 27.2rem);

        @media screen and (max-width: 820px) {
            width: 100%;
            margin-left: 0;
            padding: 0;
            overflow: unset;
            height: 100%;
        }
    }


}
.report-label{
    font-weight: 600;
    color: #FC4355;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #606060;
    border-radius: 10px;
}



.single-wrapper{

    span{
        display: inline-block;
        margin-bottom: 0 !important;
        &.inline{
            display: inline;
        }
    }
}